import { message } from 'antd';
import type { Effect } from '@@/plugin-dva/connect';
import { getTaskPools } from '@/pages/utils/service';
import type { DictionaryRecord, DictionaryRecordSchema } from '@/models/dictionary';
import type { AnyAction } from 'redux';
import type { GetTaskPoolsResponse } from '@/pages/services/moderationV3';
import { moderationV3APICommon } from '@/pages/services/moderationV3';

const { getDictionaryData, saveTaskPoolRules, saveAutoReviewRules } = moderationV3APICommon;

export interface TaskPoolRulesStateType {
  taskPoolRuleModel: TaskPoolRuleModel;
  autoReviewRuleModel: AutoReviewRuleModel;
}

export interface TaskPoolRulesModelType {
  namespace: 'taskPoolRules';
  state: TaskPoolRulesStateType;
  effects: {
    fetch: Effect;
    save: Effect;
  };
  reducers: NonNullable<unknown>;
}

export interface TaskPoolRuleModel {
  namespace?: string;
  dictionaryName?: string;
  recordSchemaId?: number;
  rules: TaskPoolRule[];
  deletedConditionIds: number[];
  taskPoolOptions: TaskPoolRuleSelectionOption[];
  fieldNameOptions: TaskPoolRuleSelectionOption[];
  matchingOptions: TaskPoolRuleSelectionOption[];
  tierOptions: TaskPoolRuleSelectionOption[];
  showButtons: boolean;
}

export interface AutoReviewRuleModel {
  namespace?: string;
  dictionaryName?: string;
  recordSchemaId?: number;
  autoReviewRules: AutoReviewRule[];
  deletedAutoReviewConditionIds: number[];
  autoReviewFieldNameOptions: TaskPoolRuleSelectionOption[];
  autoReviewMatchingOptions: TaskPoolRuleSelectionOption[];
}

export interface TaskPoolRuleSelectionOption {
  label: string;
  value: string;
  isUnary?: boolean;
}

export interface TaskPoolRule {
  taskPool?: string;
  priority: number;
  tier?: string;
  conditions: TaskPoolRuleCondition[];
}

export interface AutoReviewRule {
  taskPool?: string;
  taskStatus: string;
  conditions: AutoReviewRuleCondition[];
}

export interface TaskPoolRuleCondition {
  id?: number;
  fieldName?: string;
  criteria?: string;
  values?: string[];
}

export interface AutoReviewRuleCondition extends TaskPoolRuleCondition {
  moderationStatus: string;
}

const DEFAULT_TASK_POOL_RULE_MODEL: TaskPoolRuleModel = {
  namespace: undefined,
  dictionaryName: undefined,
  recordSchemaId: undefined,
  rules: [],
  deletedConditionIds: [],
  taskPoolOptions: [],
  fieldNameOptions: [],
  matchingOptions: [],
  tierOptions: [],
  showButtons: false,
};

const DEFAULT_AUTO_REVIEW_RULE_MODEL: AutoReviewRuleModel = {
  namespace: undefined,
  dictionaryName: undefined,
  recordSchemaId: undefined,
  autoReviewRules: [],
  deletedAutoReviewConditionIds: [],
  autoReviewFieldNameOptions: [],
  autoReviewMatchingOptions: [],
};

function getTaskPoolRuleDictionaryName(namespace: string): string {
  return namespace + '_task_pool_rules_dict';
}

function getAutoReviewRuleDictionaryName(namespace: string): string {
  return namespace + '_task_pool_auto_review_rules_dict';
}

const buildTaskPoolOptions = (taskPoolRs: GetTaskPoolsResponse) => {
  return taskPoolRs.data
    .filter((taskPool) => taskPool.type !== 'ESCALATION')
    .map((taskPool) => {
      return { value: taskPool.name, label: taskPool.description };
    });
};

const buildTierOptions = (recordSchemas: DictionaryRecordSchema[]) => {
  const recordSchema: DictionaryRecordSchema = Object.values(recordSchemas)[0];
  return recordSchema?.format.properties.tier?.enum
    .filter((tier: any) => tier === 'gold' || tier === 'bronze' || tier === 'silver') // TODO: to be removed after full release
    .map((tier: any) => {
      return { value: tier, label: formatFromCamelCase(tier) };
    });
};

function formatFromCamelCase(value: string): string {
  return value.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => {
    return str.toUpperCase();
  });
}

const getRecordSchemaId = (recordSchemas: DictionaryRecordSchema[]) => {
  const recordSchema: DictionaryRecordSchema = Object.values(recordSchemas)[0];
  if (recordSchema) {
    return recordSchema.recordSchemaId;
  } else {
    return undefined;
  }
};

const buildFieldNameOptions = (recordSchemas: DictionaryRecordSchema[]) => {
  const recordSchema: DictionaryRecordSchema = Object.values(recordSchemas)[0];
  return recordSchema?.format.properties.fieldName.enum.map((fieldName: any) => {
    return { value: fieldName, label: formatFromCamelCase(fieldName) };
  });
};

const buildCriteriaOptions = (recordSchemas: DictionaryRecordSchema[]) => {
  const recordSchema: DictionaryRecordSchema = Object.values(recordSchemas)[0];

  return recordSchema?.format.properties.criteria.enum.map((criteria: any) => {
    if (criteria === 'exact') {
      return { value: criteria, label: 'is any of' };
    } else if (criteria === 'partial') {
      return { value: criteria, label: 'has any of' };
    } else if (criteria === 'neg_exact') {
      return { value: criteria, label: 'is NOT any of' };
    } else if (criteria === 'neg_partial') {
      return { value: criteria, label: "don't have" };
    } else if (criteria === 'first_time_review_advertiser') {
      return { value: criteria, label: 'is first review advertiser', isUnary: true };
    }
    return { value: criteria, label: criteria };
  });
};

const buildTaskPoolRules = (records: DictionaryRecord[]) => {
  let rules: TaskPoolRule[] = [];
  Object.values(records)
    .sort((a, b) => (a.value.priority < b.value.priority ? -1 : 1))
    .map((value) => {
      // if part of previous rule, append condition
      if (rules[value.value.priority - 1]) {
        const ruleItem: TaskPoolRule = rules[value.value.priority - 1];
        const conditionItem: TaskPoolRuleCondition = {
          id: value.recordId,
          fieldName: value.value.fieldName,
          criteria: value.value.criteria,
          values: value.value.values,
        };
        ruleItem.conditions = [...ruleItem.conditions, conditionItem];
      } else {
        // else, create rule with condition
        const ruleItem: TaskPoolRule = {
          taskPool: value.groupKey,
          priority: value.value.priority,
          tier: value.value.tier,
          conditions: [
            {
              id: value.recordId,
              fieldName: value.value.fieldName ?? null,
              criteria: value.value.criteria ?? null,
              values: value.value.values ?? [],
            },
          ],
        };
        rules = [...rules, ruleItem];
      }
    });
  return rules;
};

const buildAutoReviewRules = (autoReviewRuleRecords: DictionaryRecord[]) => {
  const rules: AutoReviewRule[] = [];
  const ruleMap = Object.values(autoReviewRuleRecords)
    .sort((a, b) => (a.groupKey < b.groupKey ? -1 : 1))
    .reduce((ruleItem, { groupKey, recordId, value }) => {
      const autoReviewCondition: AutoReviewRuleCondition = {
        id: recordId,
        fieldName: value.fieldName,
        criteria: value.criteria,
        values: value.values,
        moderationStatus: value.moderationStatus,
      };
      if (!ruleItem[groupKey]) {
        const autoReviewRule: AutoReviewRule = {
          taskPool: groupKey,
          taskStatus: value.taskStatus,
          conditions: [autoReviewCondition],
        };
        ruleItem[groupKey] = autoReviewRule;
      } else {
        const autoReviewRule = ruleItem[groupKey];
        autoReviewRule.conditions = [...autoReviewRule.conditions, autoReviewCondition];
        ruleItem[groupKey] = autoReviewRule;
      }
      return ruleItem;
    }, {});

  Object.entries(ruleMap).map(([, val]) => {
    rules.push(<AutoReviewRule>val);
  });
  return rules;
};

const TaskPoolRulesModel: TaskPoolRulesModelType = {
  namespace: 'taskPoolRules',

  state: {
    taskPoolRuleModel: { ...DEFAULT_TASK_POOL_RULE_MODEL },
    autoReviewRuleModel: { ...DEFAULT_AUTO_REVIEW_RULE_MODEL },
  },

  effects: {
    *fetch({ payload: { namespace: namespace } }, { all, call, put }) {
      try {
        if (namespace) {
          const taskPoolRuleDictionaryName = getTaskPoolRuleDictionaryName(namespace);
          const autoReviewRuleDictionaryName = getAutoReviewRuleDictionaryName(namespace);
          const [taskPoolRs, taskPoolRuleDictionary, autoReviewRuleDictionary] = yield all([
            call(getTaskPools, namespace),
            call(getDictionaryData, namespace, taskPoolRuleDictionaryName),
            call(getDictionaryData, namespace, autoReviewRuleDictionaryName),
          ]);
          yield put({
            type: 'updateTaskPoolRules',
            payload: {
              taskPoolRuleModel: {
                namespace: namespace,
                dictionaryName: taskPoolRuleDictionaryName,
                recordSchemaId: getRecordSchemaId(taskPoolRuleDictionary.recordSchemas),
                rules: buildTaskPoolRules(taskPoolRuleDictionary.records),
                deletedConditionIds: [],
                taskPoolOptions: buildTaskPoolOptions(taskPoolRs),
                fieldNameOptions: buildFieldNameOptions(taskPoolRuleDictionary.recordSchemas),
                matchingOptions: buildCriteriaOptions(taskPoolRuleDictionary.recordSchemas),
                tierOptions: buildTierOptions(taskPoolRuleDictionary.recordSchemas),
                showButtons: true,
              },
              autoReviewRuleModel: {
                namespace: namespace,
                dictionaryName: autoReviewRuleDictionaryName,
                recordSchemaId: getRecordSchemaId(autoReviewRuleDictionary.recordSchemas),
                autoReviewRules: buildAutoReviewRules(autoReviewRuleDictionary.records),
                deletedAutoReviewConditionIds: [],
                autoReviewFieldNameOptions: buildFieldNameOptions(
                  autoReviewRuleDictionary.recordSchemas,
                ),
                autoReviewMatchingOptions: buildCriteriaOptions(
                  autoReviewRuleDictionary.recordSchemas,
                ),
              },
            },
          });
        } else {
          yield put({
            type: 'updateTaskPoolRules',
            payload: {
              taskPoolRuleModel: DEFAULT_TASK_POOL_RULE_MODEL,
              autoReviewRuleModel: DEFAULT_AUTO_REVIEW_RULE_MODEL,
            },
          });
        }
      } catch (e) {
        message.error('Failed to fetch task pool rules: ' + e);
      }
    },
    *save(
      {
        payload: {
          namespace: namespace,
          saveTaskPoolRuleRequest: saveTaskPoolRuleRequest,
          saveAutoReviewRuleRequest: saveAutoReviewRuleRequest,
        },
      },
      { all, call, put },
    ) {
      try {
        const [saveTaskPoolRulesRs, taskPoolRs, taskPoolRuleDictionary] = yield all([
          call(saveTaskPoolRules, namespace, saveTaskPoolRuleRequest),
          call(getTaskPools, namespace),
          call(getDictionaryData, namespace, saveTaskPoolRuleRequest.dictionaryName),
        ]);
        const [saveAutoReviewRulesRs, autoReviewRuleDictionary] = yield all([
          call(saveAutoReviewRules, namespace, saveAutoReviewRuleRequest),
          call(getDictionaryData, namespace, saveAutoReviewRuleRequest.dictionaryName),
        ]);
        yield put({
          type: 'updateTaskPoolRules',
          payload: {
            taskPoolRuleModel: {
              namespace: namespace,
              dictionaryName: saveTaskPoolRuleRequest.dictionaryName,
              recordSchemaId: saveTaskPoolRuleRequest.recordSchemaId,
              rules: saveTaskPoolRulesRs.rules,
              deletedConditionIds: [],
              taskPoolOptions: buildTaskPoolOptions(taskPoolRs),
              fieldNameOptions: buildFieldNameOptions(taskPoolRuleDictionary.recordSchemas),
              matchingOptions: buildCriteriaOptions(taskPoolRuleDictionary.recordSchemas),
              tierOptions: buildTierOptions(taskPoolRuleDictionary.recordSchemas),
              showButtons: true,
            },
            autoReviewRuleModel: {
              namespace: namespace,
              dictionaryName: saveAutoReviewRuleRequest.dictionaryName,
              recordSchemaId: saveAutoReviewRuleRequest.recordSchemaId,
              autoReviewRules: saveAutoReviewRulesRs.rules,
              deletedAutoReviewConditionIds: [],
              autoReviewFieldNameOptions: buildFieldNameOptions(
                autoReviewRuleDictionary.recordSchemas,
              ),
              autoReviewMatchingOptions: buildCriteriaOptions(
                autoReviewRuleDictionary.recordSchemas,
              ),
            },
          },
        });
        message.success(
          'Changes to the task pool rules have been saved.\nThe changes will be reflected immediately.',
        );
      } catch (e) {
        message.error('Failed to save task pool rules: ' + e);
      }
    },
  },
  reducers: {
    updateTaskPoolRules(state: TaskPoolRulesStateType, action: AnyAction) {
      return {
        ...state,
        taskPoolRuleModel: action.payload.taskPoolRuleModel || {},
        autoReviewRuleModel: action.payload.autoReviewRuleModel || {},
      };
    },
    updateRulesAndDeletedConditions(state: TaskPoolRulesStateType, action: AnyAction) {
      return {
        ...state,
        taskPoolRuleModel: {
          namespace: state.taskPoolRuleModel.namespace,
          dictionaryName: state.taskPoolRuleModel.dictionaryName,
          recordSchemaId: state.taskPoolRuleModel.recordSchemaId,
          rules: action.payload.taskPoolRuleModel.rules,
          deletedConditionIds: action.payload.taskPoolRuleModel.deletedConditionIds,
          taskPoolOptions: state.taskPoolRuleModel.taskPoolOptions,
          fieldNameOptions: state.taskPoolRuleModel.fieldNameOptions,
          matchingOptions: state.taskPoolRuleModel.matchingOptions,
          tierOptions: state.taskPoolRuleModel.tierOptions,
          showButtons: state.taskPoolRuleModel.showButtons,
        },
        autoReviewRuleModel: {
          namespace: state.autoReviewRuleModel.namespace,
          dictionaryName: state.autoReviewRuleModel.dictionaryName,
          recordSchemaId: state.autoReviewRuleModel.recordSchemaId,
          autoReviewRules: action.payload.autoReviewRuleModel.autoReviewRules,
          deletedAutoReviewConditionIds:
            action.payload.autoReviewRuleModel.deletedAutoReviewConditionIds,
          autoReviewFieldNameOptions: state.autoReviewRuleModel.autoReviewFieldNameOptions,
          autoReviewMatchingOptions: state.autoReviewRuleModel.autoReviewMatchingOptions,
        },
      };
    },
  },
};

export default TaskPoolRulesModel;
